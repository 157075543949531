<script setup lang="ts">
import { Button, Icon } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";

interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
  hideBack?: boolean;
  blockClass?: string;
}

const props = defineProps<Props>();
const { t } = useI18n();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    color="dark"
    flip-direction="x"
    :block-class="[props.blockClass, $style.root]"
  >
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]" v-html="t('block.typesOfCards.title')" />
    <template v-if="!props.hideBack" #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{ t("block.typesOfCards.modal_1") }}</p>
          <p>{{ t("block.typesOfCards.modal_2") }}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-white flex flex-col justify-center gap-[70px] xs:gap-[100px];
  background: linear-gradient(154.6deg, #306BD5 0%, #FF7D00 100%);
}

.title {
  @apply leading-[85%] font-black;

  &-en,
&-da,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-it,
  &-tr,
  &-ua,
  &-zh,
  &-hi,
  &-bn,
  &-sw {
    @apply text-[70px] xxs:text-[84px] xs:text-[98px];
    @apply md:text-[88px];
    @apply lg:text-[112px];
    @apply xl:text-[132px];
  }
  &-fi {
    @apply text-[68px];
    @apply md:text-[68px];
    @apply lg:text-[80px];
    @apply xl:text-[100px];
  }
  &-zh {
    @apply leading-[1.1];
  }
  &-pl, &-ar {
    @apply text-[54px] xxs:text-[66px] xs:text-[78px];
    @apply md:text-[68px];
    @apply lg:text-[88px];
    @apply xl:text-[104px];
  }
  &-et, &-id {
    @apply text-[54px] xxs:text-[66px] xs:text-[78px];
    @apply md:text-[62px];
    @apply lg:text-[78px];
    @apply xl:text-[80px];
  }
  &-hu {
    @apply text-[54px] xxs:text-[66px] xs:text-[78px];
    @apply md:text-[62px];
    @apply lg:text-[78px];
    @apply xl:text-[80px];
  }
  &-vi,
  &-fr {
    @apply text-[58px] xxs:text-[72px] xs:text-[84px];
    @apply md:text-[72px];
    @apply lg:text-[92px];
    @apply xl:text-[110px];
  }
  &-vi,
  &-th {
    @apply leading-[1.05];
  }
  &-th,
  &-es {
    @apply text-[62px] xxs:text-[76px] xs:text-[88px];
    @apply md:text-[78px];
    @apply lg:text-[98px];
    @apply xl:text-[118px];
  }
  &-pt {
    @apply text-[62px] xxs:text-[76px] xs:text-[88px];
    @apply md:text-[80px];
    @apply lg:text-[102px];
    @apply xl:text-[120px];
  }
  &-de {
    @apply text-[64px] xxs:text-[80px] xs:text-[92px];
    @apply md:text-[80px];
    @apply lg:text-[106px];
    @apply xl:text-[124px];
  }
  &-ru {
    @apply text-[82px] xxs:text-[98px] xs:text-[112px];
    @apply md:text-[98px];
    @apply lg:text-[112px];
    @apply xl:text-[132px];
  }
  &-ko,
  &-ja {
    @apply leading-[1.1];
    @apply text-[62px];
    @apply md:text-[76px];
    @apply lg:text-[98px];
    @apply xl:text-[116px];
  }
}


.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[20px] pb-5;
    @apply md:text-[24px];
    @apply lg:text-[32px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>