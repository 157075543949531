<script setup lang="ts">
import { Button, Icon } from "@/components/Ui";
import BlockFlip from "./BlockFlip.vue";
import { onDashboard } from "@/helpers/events";
import { useTextDirection } from "@vueuse/core";
interface Props {
  backOpenEvent?: string;
  backCloseEvent?: string;
  backButtonEvent?: string;
  hideBack?: boolean;
  blockClass?: string;
}
const props = defineProps<Props>();
const { t } = useI18n();
const dir = useTextDirection();
</script>
<template>
  <BlockFlip
    :back-open-event="props.backOpenEvent"
    :back-close-event="props.backCloseEvent"
    color="semi-light"
    flip-direction="x"
    :block-class="[props.blockClass, $style.root]"
  >
    <div class="flex flex-col gap-5">
      <span :class="$style.subtitle">
        {{ $t("block.noMoreRisk.subtitle") }}
      </span>
      <div :class="[$style.title, $style[`title-${$i18n.locale}`]]">
        {{ $t("block.noMoreRisk.title1") }}<br />
        <span class="italic">{{ $t("block.noMoreRisk.title2") }}</span>
      </div>
    </div>
    <Icon
      name="no-more-risk-social"
      path="pattern"
      class="scale-[1.18] md:scale-[1.3]"
    />
    <template v-if="!props.hideBack" #back>
      <div :class="$style.modal">
        <div class="pb-10">
          <p>{{ t("block.noMoreRisk.modal_1") }}</p>
          <p>{{ t("block.noMoreRisk.modal_2") }}</p>
          <p>{{ t("block.noMoreRisk.modal_3") }}</p>
        </div>
        <Button
          :title="$t('btn.register')"
          color="orange"
          icon="arrow-outline-right"
          icon-pos="right"
          :icon-class="dir === 'ltr' ? '' : 'rotate-180'"
          :class="$style.button"
          @click="onDashboard(
                String(props.backButtonEvent),
                '/register',
                {},
                {},
                true
              )"
        />
      </div>
    </template>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-neutral-800 flex flex-col justify-center gap-[70px] xs:gap-[100px];
  @apply bg-neutral-100;
}

.title {
  @apply leading-[85%] font-black;

  &-en,
&-id,
&-da,
&-et,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-bg,
  &-es,
  &-fr,
  &-ua,
  &-hi,
  &-ar,
  &-sw {
    @apply text-[52px] xxs:text-[64px];
    @apply md:text-[74px];
    @apply lg:text-[92px];
  }
  &-bn {
    @apply text-[60px] xxs:text-[64px];
    @apply md:text-[58px];
    @apply lg:text-[74px];
    @apply leading-[1.2];
  }
  &-vi, &-tr, &-hu {
    @apply text-[48px] xxs:text-[56px];
    @apply md:text-[40px];
    @apply lg:text-[50px];
    @apply xl:text-[60px];
  }
  &-ko,
  &-zh,
  &-ja {
    @apply leading-[1.1];
    @apply text-[48px] xxs:text-[60px];
    @apply md:text-[60px];
    @apply lg:text-[78px];
    @apply xl:text-[92px];
  }
  &-pt,
  &-it {
    @apply text-[48px] xxs:text-[56px];
    @apply md:text-[62px];
    @apply lg:text-[78px];
    @apply xl:text-[92px];
  }
  &-th,
  &-de {
    @apply text-[60px] xxs:text-[64px];
    @apply md:text-[56px];
    @apply lg:text-[72px];
    @apply xl:text-[86px];
  }
  &-bg {
    @apply text-[58px];
    @apply md:text-[60px];
    @apply lg:text-[76px];
    @apply xl:text-[90px];
  }
  &-pl,
  &-be,
  &-ru {
    @apply text-[54px] xxs:text-[64px];
    @apply md:text-[68px];
    @apply lg:text-[90px];
    @apply xl:text-[92px];
  }
}

.subtitle {
  @apply text-accent-primary leading-none;
  @apply text-[26px] xxs:text-[28px] xs:text-[32px];
  @apply md:text-[28px];
  @apply lg:text-[40px];
  @apply xl:text-[42px];
}


.modal {
  @apply relative flex flex-col h-full justify-center;
  & p {
    @apply text-neutral-800 text-[20px] pb-5;
    @apply md:text-[24px];
    @apply lg:text-[32px];
  }
}

.button {
  @apply absolute bottom-0 w-full mt-auto text-[18px] xxs:text-[20px] py-[10px];
  @apply md:py-[17px];
}
</style>