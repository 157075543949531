<script setup lang="ts">
import { Icon } from "@/components/Ui";
import { onMounted, ref, useSlots, watch } from "vue";
import { useWindowSize, useElementBounding } from "@vueuse/core";
import { onEvent } from "@/helpers/events";
import { gsap } from "gsap";

interface Props {
  flipDirection?: "x" | "y";
  color?: "dark" | "light" | "semi-light";
  blockClass?: any;
  blockStyle?: any;
  backOpenEvent?: string;
  backCloseEvent?: string;
}

const props = defineProps<Props>();
const slots = useSlots();

const blockEl = ref<HTMLElement | null>(null);
const backdropEl = ref<HTMLElement | null>(null);
const wrapperEl = ref<HTMLElement | null>(null);
const frontEl = ref<HTMLElement | null>(null);
const backEl = ref<HTMLElement | null>(null);
const isFlipped = ref<boolean>(false);

const blockBounding = useElementBounding(blockEl);
const windowSize = useWindowSize();
const tl = gsap.timeline({ paused: true });

const initFlipCard = () => {
  gsap.set(wrapperEl.value, {
    transformStyle: "preserve-3d",
    transformPerspective: 1500
  });
  gsap.set(frontEl.value, {
    transformStyle: "preserve-3d",
    transformOrigin: "50% 50% 0"
  });

  gsap.set(backEl.value, {
    transformStyle: "preserve-3d",
    transformOrigin: "50% 50% 0"
  });

  const timing = 0.5;
  const backConfig = props.flipDirection === "x" ? { rotationY: 180 } : { rotationX: 180 };
  gsap.set(backEl.value, backConfig);

  const cardConfig = props.flipDirection === "x" ? { rotationY: "+=180" } : { rotationX: "+=180" };
  Object.assign(cardConfig, { duration: timing });

  tl.to(frontEl.value, cardConfig, 0);
  tl.to(backEl.value, cardConfig, 0);
  tl.to(wrapperEl.value, { z: 0, duration: timing / 2, yoyo: true, yoyoEase: true, repeat: 1 }, 0);
  tl.to(backdropEl.value, { display: "block", duration: timing / 2, backgroundColor: "rgba(0,0,0,0.5)" }, 0);
  tl.to(blockEl.value, { zIndex: 1100, duration: timing / 2 }, 0);
};
const onFlipPlay = () => {
  tl.play();
  isFlipped.value = true;
  if (props.backOpenEvent) {
    onEvent(props.backOpenEvent);
  }
};
const onFlipReverse = () => {
  tl.reverse();
  isFlipped.value = false;
  if (props.backCloseEvent) {
    onEvent(props.backCloseEvent);
  }
};

onMounted(() => {
  if (slots.back && gsap) {
    initFlipCard();
  }
});

watch(
  () => blockBounding.y.value,
  (newVal) => {
    if (
      windowSize.height.value - 180 < newVal ||
      blockBounding.height.value + newVal < 180
    ) {
      tl.reverse();
      isFlipped.value = false;
    }
  }
);
</script>

<template>
  <div ref="blockEl" :class="block.root">
    <template v-if="$slots.back">
      <Teleport to="body">
        <div ref="backdropEl" :class="block.backdrop" @click="onFlipReverse" />
      </Teleport>
    </template>
    <div ref="wrapperEl" :class="block.wrapper">
      <div
        ref="frontEl"
        :class="[block.content, props.blockClass]"
        :style="props.blockStyle"
      >
        <span
          v-if="$slots.back"
          :class="[block.open, block[`open-${props.color || 'light'}`]]"
          @click="onFlipPlay"
        >
          <Icon name="add-outlined" />
        </span>
        <slot />
      </div>

      <template v-if="$slots.back">
        <div ref="backEl" :class="[block.content, block.back]">
          <span :class="block.close" @click="onFlipReverse">
            <Icon name="close-outline" />
          </span>
          <slot name="back" />
        </div>
      </template>
    </div>
  </div>
</template>
<style lang="scss" module="block">
.root {
  @apply z-10 w-full mb-[-1px];
  @apply h-[80vh] min-h-[668px];
  @apply lg:h-[820px];
}

.backdrop {
  @apply hidden fixed top-0 left-0 bottom-0 right-0 z-10;
  transform: translateZ(0);
}

.wrapper {
  @apply relative z-20 w-full h-full min-h-full min-w-full;
}

.open {
  @apply cursor-pointer flex items-center justify-center w-12 h-12 rounded-full overflow-hidden;
  @apply absolute top-0 right-0 m-5;
  @apply transition-all hover:scale-[0.9];
  & svg {
    @apply w-6 h-6 h-[24px];
  }

  &-light {
    @apply text-white bg-white bg-opacity-30;
  }

  &-semi-light {
    @apply text-white bg-black bg-opacity-20;
  }

  &-dark {
    @apply text-white bg-white bg-opacity-30;
  }
}

.close {
  @apply flex items-center justify-center h-12 w-12 rounded-full bg-black bg-opacity-20 text-white;
  @apply transition-all focus:bg-black focus:bg-opacity-30 focus:outline-none;
  @apply hover:scale-[0.9];
  @apply absolute top-0 right-0 m-5;
  @apply cursor-pointer z-[999];
  & svg {
    @apply w-6 h-6 min-w-[24px];
  }
}

.content {
  @apply absolute top-0 left-0 h-full w-full overflow-hidden opacity-100;
  @apply px-5 py-[70px];
  @apply md:px-7 py-[50px] md:rounded-[40px];
  @apply lg:px-[50px];
  backface-visibility: hidden;
}

.back {
  @apply bg-white;
}
</style>