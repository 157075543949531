<script setup lang="ts">
import BlockFlip from "./BlockFlip.vue";
interface Props {
  blockClass?: string;
}
const props = defineProps<Props>();
const { t } = useI18n();
</script>
<template>
  <BlockFlip :block-class="[props.blockClass, $style.root]">
    <div :class="[$style.title, $style[`title-${$i18n.locale}`]]" v-html="$t('block.businessModel.title')" />
    <ul
      :class="[$style.list, $style[`list-${$i18n.locale}`]]"
    >
      <li
        v-for="index in 3"
        :key="index"
      >
        <span>0%</span> {{ t(`block.businessModel.li${index}`) }}
      </li>
    </ul>
  </BlockFlip>
</template>

<style lang="scss" module>
.root {
  @apply text-neutral-800 flex flex-col justify-center gap-10;
  @apply bg-neutral-100;
  @screen md {
    @apply bg-white gap-20;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 0px 17px 38px 0px rgba(0, 0, 0, 0.10), 0px 69px 69px 0px rgba(0, 0, 0, 0.09), 0px 155px 93px 0px rgba(0, 0, 0, 0.05), 0px 275px 110px 0px rgba(0, 0, 0, 0.01), 0px 430px 120px 0px rgba(0, 0, 0, 0.00);
  }
}

.title {
  @apply font-black leading-none;

  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-pt,
  &-th,
  &-hi,
  &-ar,
  &-bn,
  &-ko {
    @apply text-[40px] xxs:text-[50px];
    @apply md:text-[76px] md:w-9/12;
    @apply lg:text-[96px];
  }
  &-de {
    @apply text-[30px] xxs:text-[36px] xs:text-[42px];
    @apply md:text-[76px] md:w-9/12;
    @apply lg:text-[96px];
  }
  &-th {
    @apply leading-[1.4];
  }

  &-vi {
    @apply text-[46px] xxs:text-[52px];
    @apply md:text-[76px];
    @apply lg:text-[80px] xl:text-[92px];
  }

  &-zh {
    @apply leading-[1.1];
    @apply text-[46px] xxs:text-[52px];
    @apply md:text-[76px] md:w-6/12;
    @apply lg:text-[96px];
  }

  &-ua {
    @apply text-[42px] xxs:text-[48px];
    @apply md:text-[76px];
    @apply lg:text-[96px];
  }

  &-sw,
  &-pl,
  &-tr,
  &-it,
  &-fr {
    @apply text-[42px] xxs:text-[52px];
    @apply md:text-[68px];
    @apply lg:text-[88px] xl:text-[96px];
  }

  &-es {
    @apply text-[38px] xxs:text-[46px];
    @apply md:text-[72px];
    @apply lg:text-[96px];
  }

  &-bg {
    @apply text-[42px] xxs:text-[52px];
    @apply md:text-[76px] lg:w-9/12;
    @apply lg:text-[96px];
  }

  &-ru {
    @apply text-[38px] xxs:text-[48px];
    @apply md:text-[76px];
    @apply lg:text-[96px];
  }

  &-ja {
    @apply leading-[1.1];
    @apply text-[46px] xxs:text-[52px];
    @apply md:text-[74px] md:w-9/12;
    @apply lg:text-[96px];
  }
}

.list {
  @apply flex flex-col gap-[18px] mt-auto mb-5;
  @apply md:gap-7;
  & > li {
    @apply flex gap-2 md:gap-3 lg:gap-4;
  }

  @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[32px] lg:text-[44px] xl:text-[50px];

  &-be,
  &-bg,
  &-de,
  &-it,
  &-es,
  &-fr,
  &-pt,
  &-tr,
  &-hi,
  &-ua,
  &-ja,
  &-zh,
  &-ru,
  &-th,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi {
    @apply leading-[1.1] md:leading-[90%] text-[20px] xxs:text-[26px];
    @apply md:text-[36px];
    @apply lg:text-[52px];
    @apply xl:text-[64px];
  }

  &-vi {
    @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] md:text-[26px] lg:text-[36px] xl:text-[42px];
  }

  &-th {
    @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[30px] lg:text-[38px] xl:text-[46px];
  }

  &-sw {
    @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[32px] lg:text-[42px] xl:text-[48px];
  }

  &-pl,
  &-en {
    @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[32px] lg:text-[44px] xl:text-[50px];
  }
}
</style>